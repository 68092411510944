import * as React from "react";
import { List, Datagrid, TextField, NumberField  } from 'react-admin';
import { MyPagination } from './pagination';



export const InventoryItemsList = (props) => (
    <List {...props} title='Инвентарь' pagination={<MyPagination/>}>
        <Datagrid>
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="name" label="Наименование" sortable={false} />
            <TextField source="dep_id" label="DepID" sortable={false} />
            <TextField source="dep_name" label="Департамент" sortable={false} />
            <NumberField source="price" label="Цена" sortable={false} options={{ minimumFractionDigits: 2 }}/>
        </Datagrid>
    </List>
);


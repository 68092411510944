import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
} from "react-admin";


export const UnaUserList = (props) => (
  <List {...props} title="Пользователи UNA">
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Пользователь" />
    </Datagrid>
  </List>
);





import { fetchUtils, cacheDataProviderProxy } from "react-admin";
import { stringify } from "query-string";

const apiUrl = "/api/v1";
// const httpClient = fetchUtils.fetchJson;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  // console.log(url, options);
  return fetchUtils.fetchJson(url, options);
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */

function convertFileToBase64(file) {
  // console.log(typeof (file));
  // console.log(file);
  if (typeof file === "string") {
    return new Promise((resolve, reject) => {
      resolve(file);
    });
  }
  if (typeof file === "undefined") {
    return new Promise((resolve, reject) => {
      resolve(file);
    });
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
}

function mymap(resource, data) {
  // console.log(resource, data);
  if (resource === "groups") {
    return data.map((r) => ({ ...r, id: r.group_id }));
  } else if (resource === "prices") {
    return data.map((r) => ({ ...r, id: r.product_id }));
  } else if (resource === "stocks") {
    return data.map((r) => ({ ...r, id: r.product_id }));
  } else if (resource === "orders") {
    return data.map((r) => ({ ...r, id: r.nrdoc }));
  } else  if (resource === "egais") {
    return data.map((r) => ({ ...r, result_field: "/upload/"+r.folder+"/result.zip" }));
    // if (data == null) {
    //   data = new Array(0);
    // }
    // return data.map((r) => ({ ...r, id: r.nrdoc }));
  }
  return data;
}
function myfix(resource, data) {
  // console.log(resource, data);
  if (resource === "groups") {
    return { ...data, id: data.group_id };
  } else if (resource === "prices") {
    return { ...data, id: data.product_id };
  } else if (resource === "stocks") {
    return { ...data, id: data.product_id };
  } else if (resource === "orders") {
    return { ...data, id: data.nrdoc };
  }
  return data;
}

const provider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}/?${stringify(query)}`;
    // const url = `${apiUrl}/${resource}/`;

    // console.log(resource, params);
    return httpClient(url).then(({ headers, json }) => ({
      data: mymap(resource, json.data),
      total: json.total,
    }));
  },
  getOne: (resource, params) => {
    // console.log(resource, params);
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url).then(({ headers, json }) =>
      // console.log(json.data,json.data.length)
      ({
        data: json.data,
      })
    );
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => 
    // console.log(json)
    ({ 
      data: json.data
    })
    );
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
      // total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },
  update: (resource, params) => {
    if (resource === "loyaltycards") {
      return Promise.resolve(convertFileToBase64(params.data.signature)).then(
        (base64file) => {
          params.data.signature = base64file;
          return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          }).then(({ json }) => ({ data: myfix(resource, json.data) }));
        }
      );
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: myfix(resource, json.data) }));
  },
  updateMany: (resource, params) => {
    // console.log(resource, params);
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data }));
  },
  create: (resource, params) => {
    if (resource === "egais") {
      console.log(params.data);
      Promise.resolve(convertFileToBase64(params.data.xlsx)).then(
        (base64file1) => {
          params.data.xlsx.dataxml = base64file1;
        }
      );
      return Promise.resolve(convertFileToBase64(params.data.xml)).then(
        (base64file2) => {
          params.data.xml.dataxml = base64file2;
          return httpClient(`${apiUrl}/${resource}/`, {
            method: "POST",
            body: JSON.stringify(params.data),
          }).then(({ json }) => ({ data: json.data }));
        }
      );
    }
    if (resource === "meridian") {
      console.log(params.data);
      return Promise.resolve(convertFileToBase64(params.data.xml)).then(
        (base64file) => {
          params.data.xml.dataxml = base64file;
          return httpClient(`${apiUrl}/${resource}/`, {
            method: "POST",
            body: JSON.stringify(params.data),
          }).then(({ json }) => ({ data: json.data }));
        }
      );
    }

    return httpClient(`${apiUrl}/${resource}/`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json.data,
    }));
  },
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

// export default provider;
export default cacheDataProviderProxy(provider);

import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  Create,
  NumberInput,
  regex,
} from "react-admin";

const validateStockID = regex(/^\s*\d+\s*(,\s*\d+\s*)*\s*$/, "Must be a comma separated list 123,456");

export const UserList = (props) => (
  <List {...props} title="Пользователи">
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="email" label="Пользователь" />
      <BooleanField source="is_admin" label="Админ" />
      <TextField source="UserInfo.name" label="Имя" />
      <TextField source="UserInfo.phone" label="Телефон" />
    </Datagrid>
  </List>
);

export const UserShow = (props) => (
  <Show {...props} title="Пользователь">
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="email" label="Пользователь" />
      <BooleanField source="is_admin" label="Админ" />
      <TextField source="UserInfo.name" label="Имя" />
      <TextField source="UserInfo.phone" label="Телефон" />
    </SimpleShowLayout>
  </Show>
);

export const UserEdit = (props) => (
  <Edit {...props} title="Пользователь">
    <SimpleForm>
      <TextField disabled source="id" label="ID" />
      <TextInput source="email" label="Пользователь" />
      <TextInput source="password" label="Пароль" />
      <BooleanInput source="is_admin" label="Админ" />
      <TextInput source="UserInfo.name" label="Имя" />
      <TextInput source="UserInfo.phone" label="Телефон" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props} title="Пользователь">
    <SimpleForm>
      <TextInput source="email" label="Пользователь" />
      <TextInput source="password" label="Пароль" />
      <BooleanInput source="is_admin" label="Админ" defaultValue={false} />
      <TextInput source="UserInfo.name" label="Имя" />
      <TextInput source="UserInfo.phone" label="Телефон" />
      <NumberInput source="UserInfo.supplier_id" label="Номер поставщика" />
      <TextInput
        source="UserInfo.online_stock_id"
        label="Остатки по магазинам"
        validate={validateStockID}
      />
    </SimpleForm>
  </Create>
);

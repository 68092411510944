import * as React from "react";
import { List, Datagrid, TextField, DateField, Show, SimpleShowLayout, Filter, TextInput, DateInput } from 'react-admin';
import { MyPagination } from './pagination';

export const LogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Ip" source="ip" defaultValue="127.0.0.1" />
        <DateInput label="Дата" source="created_at" />
        <TextInput label="Пользователь" source="username" />
        <TextInput label="Текст" source="message" />
        <TextInput label="URI" source="uri" />
        <TextInput label="Order" source="web_order_id" />
    </Filter>
);

export const LogList = (props) => (
    <List {...props} title='Логи' filters={<LogFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            {/* <TextField source="id" label="ID" /> */}
            <DateField label="Дата" source="created_at" showTime={true} noWrap={true} />
            <TextField source="ip" label="IP" />
            <TextField source="username" label="User" />
            <TextField source="message" label="Текст" />
            <TextField source="uri" label="URI" />
            <TextField source="web_order_id" label="Order" />
        </Datagrid>
    </List>
);

export const LogShow = (props) => (
    <Show {...props} title='Логи'>
        <SimpleShowLayout>
            {/* <TextField source="id" label="ID" /> */}
            <DateField label="Дата" source="created_at" />
            <TextField source="ip" label="IP" />
            <TextField source="username" label="User" />
            <TextField source="message" label="Текст" />
            <TextField source="uri" label="URI" />
            <TextField source="web_order_id" label="Order" />
        </SimpleShowLayout>
    </Show>
);

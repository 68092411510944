import * as React from "react";
import { Admin, Resource } from 'react-admin';
// import { ListGuesser } from 'react-admin';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { UserList, UserShow, UserEdit, UserCreate } from './users';
import { UnaUserList } from './unausers';
import { InventoryItemsList } from './inventory';
import { ShopList, ShopShow } from './shops';

// import { MarkList } from './mark';
import { LogList, LogShow } from './logs';
// import { MyDashboard } from "./Dashboard";
import UserIcon from '@material-ui/icons/Group';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ContactsIcon from '@material-ui/icons/Contacts';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import polyglotI18nProvider from 'ra-i18n-polyglot'; // install the package
import englishMessages from 'ra-language-english'; // install the package

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

const App = () => (
    <Admin disableTelemetry i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider} >
        {permissions => [
            // permissions === 'admin'
            //     ?
            //     <MyDashboard />
            //     : null,
            permissions === 'admin'
                ?
                <Resource name="users" options={{ label: 'Users' }} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={UserIcon} />
                : null,
            permissions === 'admin'
                ?
                <Resource name="logs" options={{ label: 'Logs' }} list={LogList} show={LogShow} icon={RecentActorsIcon} />
                : null,
            <Resource name="shops"        options={{ label: 'Магазины' }}         list={ShopList}        show={ShopShow}        icon={HomeWorkIcon} />,
            <Resource name="unausers"     options={{ label: 'Пользователи UNA' }} list={UnaUserList}                            icon={ContactsIcon} />,
            <Resource name="inventory"    options={{ label: 'Интвентарь' }}       list={InventoryItemsList}                     icon={SystemUpdateIcon} />

        ]}
    </Admin>
);
export default App;